import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import ProfileBadge from '@/components/Admin/Profile/ProfileBadge'

const IndexNavigation = () => {
  const { t } = useTranslation()
  const pathname = usePathname()
  const { startLoading } = useLoadingState()
  const { user } = useAuth()
  const { settings } = useDbSettings()

  const isDisabled = () => {
    if (!settings?.requireSubscription) {
      return false
    } else {
      return !user?.subscribed
    }
  }

  return (
    <div className='flex items-center gap-2 text-sm sm:gap-8 sm:text-base'>
      {user ? (
        <>
          {!isDisabled() && (
            <>
              {user.role === 'ADMIN' ? (
                <Link
                  href='/admin/dashboard'
                  target='_blank'
                  className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
                >
                  {t('admin-dashboard')}
                </Link>
              ) : (
                <Link
                  href='/user/documents'
                  target='_blank'
                  className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
                >
                  {t('user-dashboard')}
                </Link>
              )}
            </>
          )}

          <ProfileBadge />
        </>
      ) : (
        <Link
          href='/signup'
          onClick={() => pathname !== '/signup' && startLoading()}
          className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
        >
          {t('sign-up')}
        </Link>
      )}
    </div>
  )
}

export default IndexNavigation
