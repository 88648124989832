import { Switch } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import LanguageSwitch from '@/components/Admin/LanguageSwitch'

interface ChatbotSettingsProps {
  userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }
  setUserSettings: (userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }) => void
  adminChatbot?: boolean
}

const ChatbotSettings: React.FC<ChatbotSettingsProps> = ({
  userSettings,
  setUserSettings,
  adminChatbot,
}) => {
  const { t } = useTranslation()

  return (
    <div className='flex w-full flex-col items-center gap-2 py-1 text-sm text-on-surface/70 dark:text-dark-on-surface/70'>
      <div className='flex w-full justify-between'>
        <div className='flex flex-col gap-1'>
          <span className='font-bold text-on-surface dark:text-dark-on-surface'>
            {adminChatbot
              ? t('popover-gpt-turbo-title')
              : t('popover-gpt-title')}
          </span>
          <div className='max-w-[220px] text-xs'>
            {adminChatbot ? t('popover-admin-chatbot-gpt') : t('popover-gpt')}
          </div>
        </div>
        <Switch
          onChange={(checked: boolean) =>
            setUserSettings({ ...userSettings, useGpt4: checked })
          }
          checked={userSettings.useGpt4}
          className='bg-on-surface/20 dark:bg-dark-on-surface/20'
        />
      </div>
      <hr className='w-full border-on-surface/50 dark:border-dark-on-surface/50' />
      <div className='flex w-full justify-between gap-2'>
        <div className='flex flex-col gap-1'>
          <span className='font-bold text-on-surface dark:text-dark-on-surface'>
            {t('popover-streaming-title')}
          </span>
          <div className='max-w-[220px] text-xs'>{t('popover-streaming')}</div>
        </div>
        <Switch
          onChange={(checked: boolean) =>
            setUserSettings({ ...userSettings, useStreaming: checked })
          }
          checked={userSettings.useStreaming}
          className='bg-on-surface/20 dark:bg-dark-on-surface/20'
        />
      </div>
      <hr className='w-full border-on-surface/50 dark:border-dark-on-surface/50' />
      <div className='flex w-full justify-between gap-2'>
        <div className='flex flex-col gap-1'>
          <span className='font-bold text-on-surface dark:text-dark-on-surface'>
            {t('drafting-title')}
          </span>
          <div className='max-w-[220px] text-xs'>{t('drafting')}</div>
        </div>
        <Switch
          onChange={(checked: boolean) =>
            setUserSettings({ ...userSettings, useDrafting: checked })
          }
          checked={userSettings.useDrafting}
          className='bg-on-surface/20 dark:bg-dark-on-surface/20'
        />
      </div>
      <hr className='w-full border-on-surface/50 dark:border-dark-on-surface/50' />
      <LanguageSwitch className='self-start' />
    </div>
  )
}

export default ChatbotSettings
