'use client'

import { Button, Form, Input, message, Modal } from 'antd'
import axios from 'axios'
import Link from 'next/link'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'

import { configHeader } from '@/constants/api'
import { API_URL, COMPANY } from '@/constants/env'

import { IAxiosError } from '@/types/api'
const { TextArea } = Input

interface ContactUsModalProps {
  chat: { question: string; message: string }[]
}

const ContactUsModal: React.FC<ContactUsModalProps> = ({ chat }) => {
  const { toggleModal, openModal } = useModalsState()
  const { t } = useTranslation()
  const [isSending, setIsSending] = useState(false)

  const onFinish = async (values: any) => {
    setIsSending(true)
    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({ ...values, chat, company: COMPANY }),
    }

    try {
      await axios(`${API_URL}/chatbot/contact-us`, config)
      toggleModal('contactUs')
      message.success(t('message-sent'))
    } catch (error) {
      console.error(error)
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
    }
    setIsSending(false)
  }

  return (
    <Modal
      centered
      open={openModal === 'contactUs'}
      onCancel={() => toggleModal('contactUs')}
      footer={null}
      title={<span className='text-xl'>{t('contact-us')}</span>}
      wrapClassName='hide-scrollbar'
      className='rounded-lg border border-on-surface/20 dark:border-dark-on-surface/20'
    >
      <Form
        labelCol={{ flex: '140px' }}
        labelAlign='left'
        onFinish={onFinish}
        className='mt-6'
      >
        <Form.Item
          label={t('contact-us-first-name')}
          name='name'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('contact-us-surname')}
          name='surname'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('contact-us-email')}
          name='email'
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('contact-us-phone-number')}
          name='phoneNumber'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t('contact-us-message')} name='message'>
          <TextArea
            showCount
            maxLength={2000}
            autoSize={{ maxRows: 10, minRows: 3 }}
          />
        </Form.Item>

        <Form.Item>
          <div className='flex w-full justify-center'>
            <Button
              htmlType='submit'
              className='w-full sm:w-auto'
              loading={isSending}
            >
              {t('contact-us-send-message')}
            </Button>
          </div>
        </Form.Item>
      </Form>

      <div className='mt-6'>
        {t('contact-us-terms-privacy-text')}{' '}
        <Link
          href='https://docs.google.com/document/d/1nBiOFMq1olWUKsgFjPRHvYIzPZsHdYjDgad0rc4Ryf4/preview'
          target='_blank'
          className='text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
        >
          {t('contact-us-terms-and-conditions')}
        </Link>{' '}
        {t('contact-us-terms-privacy-connect-text')}{' '}
        <Link
          href='https://docs.google.com/document/d/1bKb9yoN5qe3VjdGZGbuxx62QxDw8zalB2hBab7Hs3Bk/preview'
          target='_blank'
          className='text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
        >
          {t('contact-us-privacy-policy')}
        </Link>
      </div>
    </Modal>
  )
}

export default ContactUsModal
