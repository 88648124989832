import type { MenuProps } from 'antd'
import { Avatar, Dropdown, Tooltip } from 'antd'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { API_URL } from '@/constants/env'
import { DrawerContext } from '@/context/conversationDrawer/drawer.context'
import { cn } from '@/utils/clsx'

const IndexDropdown: React.FC = () => {
  const { t } = useTranslation()
  const pathname = usePathname()
  const { startLoading } = useLoadingState()
  const { show } = useContext(DrawerContext)
  const { user } = useAuth()
  const { settings } = useDbSettings()
  const { settings: dbSettings } = useDbSettings()

  const signUpCompleted =
    (dbSettings?.requireSubscription && user?.subscribed) ||
    (!dbSettings?.requireSubscription &&
      dbSettings?.useDocumenso &&
      !user?.signed) ||
    (!dbSettings?.requireSubscription && !dbSettings?.useDocumenso)

  const isDisabled = () => {
    if (!settings?.requireSubscription) {
      return false
    } else {
      return !user?.subscribed
    }
  }

  const items: MenuProps['items'] = []

  if (user?.role === 'ADMIN') {
    items.push(
      {
        label: (
          <Link href='/admin/dashboard' target='_blank' className='px-3 py-1'>
            {t('admin-dashboard')}
          </Link>
        ),
        key: '0',
      },
      {
        type: 'divider',
        key: '1',
      }
    )
  } else if (user?.role === 'USER' && !isDisabled()) {
    items.push(
      {
        label: (
          <Link href='/user/documents' target='_blank' className='px-3 py-1'>
            {t('user-dashboard')}
          </Link>
        ),
        key: '0',
      },
      {
        type: 'divider',
        key: '1',
      }
    )
  }

  if (!signUpCompleted) {
    items.push(
      {
        label: (
          <Link
            href='/signup'
            onClick={startLoading}
            className='px-3 py-1 !text-warning dark:!text-dark-warning'
          >
            {t('sign-up-warning-complete')}
          </Link>
        ),
        key: '2',
      },
      {
        type: 'divider',
        key: '3',
      }
    )
  }

  items.push(
    {
      label: (
        <>
          {user && (
            <Link href='' onClick={show} className='px-3'>
              {t('conversations')}
            </Link>
          )}
        </>
      ),
      key: '2',
    },
    {
      type: 'divider',
      key: '3',
    },
    {
      label: (
        <Link href={`${API_URL}/auth/logout`} className='px-3'>
          {t('logout')}
        </Link>
      ),
      key: '4',
    }
  )

  if (user) {
    return (
      <Dropdown menu={{ items }} placement='bottomRight'>
        <Tooltip
          open={!signUpCompleted ?? undefined}
          title={t('sign-up-warning-complete')}
          placement='left'
        >
          <Avatar
            className={cn(
              'cursor-pointer',
              signUpCompleted
                ? 'text-on-primary dark:text-dark-on-primary bg-primary dark:bg-dark-primary'
                : 'text-on-warning dark:text-dark-on-warning bg-warning dark:bg-dark-warning'
            )}
          >
            <span className='text-lg'>
              {signUpCompleted
                ? (user.name ?? '').slice(0, 1).toUpperCase()
                : '!'}
            </span>
          </Avatar>
        </Tooltip>
      </Dropdown>
    )
  } else {
    return (
      <Link
        href='/signup'
        onClick={() => pathname !== '/signup' && startLoading()}
        className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
      >
        {t('sign-up')}
      </Link>
    )
  }
}

export default IndexDropdown
