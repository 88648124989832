'use client'

import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiErrorWarningLine,
} from '@remixicon/react'
import { Card, Collapse, Skeleton } from 'antd'
import Image from 'next/image'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import GeneratedButton from '@/components/Chatbot/ToolsGenerated/GeneratedButton'
import GeneratedForm from '@/components/Chatbot/ToolsGenerated/GeneratedForm'
import GeneratedRadioGroup from '@/components/Chatbot/ToolsGenerated/GeneratedRadioGroup'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'

import ActionButtons from './ActionButtons'
import GeneratedCheckboxGroup from '../../ToolsGenerated/GeneratedCheckboxGroup'
import chatbotAvatar from '../../../../../public/chatbotAvatar.png'

import { Agent } from '@/types'
import { IMessage } from '@/types/chatbot'

interface AnswerProps {
  question: string
  questionInfo: IMessage
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
  onSelectedDraft: (draft: string) => void
  onReferencesClick: (documents: string[], googleDriveUrls: string[]) => void
  isLastAnswer?: boolean
  agents?: Agent[]
  className?: string
  updateQuestion: (question: IMessage) => void
}

const Answer: React.FC<AnswerProps> = ({
  question,
  questionInfo,
  getAnswer,
  onSelectedDraft,
  onReferencesClick,
  isLastAnswer,
  agents,
  className,
  updateQuestion,
}) => {
  const {
    role,
    type,
    message,
    timestamp,
    domain,
    drafts,
    agent,
    isCommand,
    button,
    form,
    radio_group,
    checkbox_group,
    audio,
  } = questionInfo
  const [avatar, setAvatar] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const formatQuestion = (question: string) => {
    return (question.charAt(0).toUpperCase() + question.slice(1))
      .split('\n')
      .map((line, index) => {
        return line !== '' ? <p key={index}>{line}</p> : <br key={index} />
      })
  }

  const formattedQuestion = useMemo(() => formatQuestion(message), [message])

  useEffect(() => {
    if (agents !== undefined && agent !== undefined) {
      const a = agents?.find((a) => a.id === agent)?.avatar
      setAvatar(a)
    }
  }, [agents, agent])

  return (
    <div className={cn('px-4 py-2', className)}>
      {role === 'user' && message && (
        <section className='bubble-slide-left relative rounded-xl rounded-br-none shadow-md'>
          <div className='rounded-xl rounded-br-none bg-primary px-4 py-2 text-left dark:bg-dark-primary'>
            <div className='break-words text-left text-sm text-on-primary dark:text-dark-on-primary'>
              {domain ? formattedQuestion : t('use-external-data')}
            </div>
            <p className='text-[10px] font-light text-on-primary/70 dark:text-dark-on-primary/70'>
              {timestamp}
            </p>
          </div>
        </section>
      )}

      {role === 'assistant' && !isCommand && (
        <section className='bubble-slide-right relative mb-3 rounded-xl rounded-bl-none shadow-md'>
          <div className='rounded-xl rounded-bl-none bg-surface px-4 pb-6 pt-2 text-on-surface dark:bg-dark-surface dark:text-dark-on-surface'>
            <div className='mb-14 mt-1 flex flex-col gap-4'>
              {!domain && (
                <div className='flex flex-col gap-2'>
                  <div className='flex items-center justify-center gap-1.5'>
                    <RiErrorWarningLine className='size-5' />
                    <div className='flex items-center justify-center text-xs font-bold'>
                      {t('external-data-header')}
                    </div>
                  </div>
                  <hr />
                </div>
              )}
              {isLastAnswer && drafts.length > 1 && (
                <Collapse
                  size='small'
                  expandIconPosition='end'
                  bordered={false}
                  collapsible={drafts.length > 1 ? 'header' : 'disabled'}
                  className={drafts.length > 1 ? 'opacity-100' : 'opacity-50'}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <RiArrowUpSLine className='fill-on-surface' />
                    ) : (
                      <RiArrowDownSLine className='fill-on-surface' />
                    )
                  }
                  items={[
                    {
                      key: '1',
                      label: (
                        <div className='ml-auto w-fit select-none text-on-surface dark:text-dark-on-surface'>
                          {t('view-other-drafts')}
                        </div>
                      ),
                      children: (
                        <div className='grid grid-cols-3 gap-2'>
                          {drafts.map((draft, index) => (
                            <Card
                              key={index}
                              className='bg-primary/30 text-left text-sm opacity-[95%] hover:opacity-100 dark:bg-dark-primary/30'
                              bodyStyle={{
                                padding: '6px',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                              }}
                              style={{ maxHeight: '200px' }}
                              hoverable
                              onClick={() => onSelectedDraft(draft)}
                            >
                              <div className='flex flex-col gap-1'>
                                <div className='text-xs font-semibold text-on-surface dark:text-dark-on-surface'>
                                  {t(`draft-${index + 1}`)}
                                </div>
                                <div className='line-clamp-4 text-xs text-on-surface/70 dark:text-dark-on-surface/70'>
                                  {draft}
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                      ),
                    },
                  ]}
                />
              )}

              <div className='markdown-answer break-words text-left text-sm'>
                {type === 'text' ? (
                  message !== undefined ? (
                    // make links inside answers open in new tab
                    markdown(message)
                  ) : (
                    t('no-answer')
                  )
                ) : type === 'image_url' && message ? (
                  <Image src={message} alt='Image' width={500} height={500} />
                ) : (
                  t('no-answer')
                )}
                {audio && (
                  <audio
                    src={`data:audio/wav;base64,${audio}`}
                    controls
                    className='mt-4'
                  />
                )}
              </div>
            </div>
          </div>

          {agents && agent ? (
            <Image
              src={avatar ? `data:image/png;base64, ${avatar}` : chatbotAvatar}
              alt='Chatbot avatar'
              className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
              width={avatar ? 16 : undefined}
              height={avatar ? 16 : undefined}
            />
          ) : (
            <Skeleton.Image
              active={true}
              className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
            />
          )}

          <ActionButtons
            question={question}
            questionInfo={questionInfo}
            getAnswer={getAnswer}
            onReferencesClick={onReferencesClick}
            updateQuestion={updateQuestion}
          />
        </section>
      )}
      {isLastAnswer && (
        <div className='mt-8 text-left'>
          {button && (
            <GeneratedButton text={button.text} getAnswer={getAnswer} />
          )}
          {form && <GeneratedForm items={form.items} getAnswer={getAnswer} />}
          {radio_group && (
            <GeneratedRadioGroup
              items={radio_group.items}
              getAnswer={getAnswer}
            />
          )}
          {checkbox_group && (
            <GeneratedCheckboxGroup
              items={checkbox_group.items}
              getAnswer={getAnswer}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Answer
